<template>
  <div>
    <b-card no-body class="">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </div>
      </div>
    </b-card>

    <b-tabs v-model="tabIndex" content-class="mt-1">
      <b-tab v-for="tag in tags" :key="tag.uuid" :title="tag.name">
        <b-card no-body class="mb-0">
          <b-table
            sticky-header
            class="position-relative"
            :items="tag.tags.data"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-filtered-text="Nenhum registro encontrado"
            empty-text="Nenhum registro encontrado"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>

            <template #cell(actions)="data">
              <div class="d-flex-between">
                <feather-icon
                  :id="`customer-${data.item.uuid}-delete-icon`"
                  icon="TrashIcon"
                  class="cursor-pointer cursor text-danger"
                  size="16"
                  @click="confirmDelete(data.item.uuid)"
                />

                <feather-icon
                  :id="`customer-${data.item.uuid}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer cursor"
                  size="16"
                  @click="modalEdit(data.item.uuid)"
                />
              </div>
            </template>
          </b-table>

          <div class="mx-2 mb-2 mt-2 paginate-area">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="">
                  Foram encontrados {{ tag.tags.meta.total }} resultados
                </span>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="tag.tags.meta.current_page"
                  :total-rows="tag.tags.data.length"
                  :per-page="tag.tags.meta.total"
                  :limit="10"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="my-1"
                  @input="getData()"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-modal
      id="modal-create-tag"
      modal-class="modal-create"
      :title="titleModal"
      centered
      hide-footer
    >
      <b-form @submit.prevent="submitCreate">
        <b-row class="form-row">
          <b-col cols="7">
            <label for="">
              Nome
              <i class="text-danger bi bi-record-circle"></i>
            </label>
            <div class="form-group">
              <b-form-input
                v-model="item.name"
                :class="{ 'is-invalid': $v.item.name.$error }"
              />
            </div>
          </b-col>
          <b-col cols="5">
            <label for="">
              Categoria
              <i class="text-danger bi bi-record-circle"></i>
            </label>
            <div class="form-group">
              <v-select
                v-model="item.category"
                label="title"
                required
                :clearable="false"
                :options="categoriesOptions"
                :class="{ 'is-invalid': $v.item.category.$error }"
              />
            </div>
          </b-col>
        </b-row>
        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button
              type="button"
              @click="$bvModal.hide('modal-create-tag')"
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <ButtonsActionsFooter
              variant="success"
              :submited="submited"
              :text="textSubmit"
              subtext="Aguarde..."
            />
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
    BMedia,
  },
  data() {
    return {
      tabIndex: null,
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 300,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "300",
          value: 300,
        },
        {
          title: "500",
          value: 500,
        },
      ],
      categoriesOptions: [],
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
        category: [],
      },
    };
  },
  validations: {
    item: {
      name: {
        required,
        minLength: minLength(3),
      },
      category: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-tag", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Tag";
      this.$bvModal.show("modal-create-tag");
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-tag");
  },
  methods: {
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("Tag/show", uuid).then(() => {
        this.item = this.tag;
        this.uuid = this.item.uuid;
        this.$bvModal.show("modal-create-tag");
      });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Tag/create", this.item)
            .then(() => {
              this.getData();
              this.notifyDefault("success");

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-tag");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Tag/update", { uuid: this.uuid, data: this.item })
            .then(() => {
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-tag");
                this.clearForm();
              });
              this.notifyDefault("success");
            })
            .catch(() => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        name: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("Tag/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then(() => {
          this.categoriesOptions = [];
          for (let i = 0; i < this.tags.length; i++) {
            this.categoriesOptions.push({
              title: this.tags[i].name,
              code: this.tags[i].uuid,
            });
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Tag/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("Tag", ["tags", "tag", "tagCategories"]),
  },
};
</script>
